<template>
  <div>
    <v-row>
      <v-col md="12">
        <DateSelector
          :dates="dates"
          @setDates="setNewDates"
          class="mb-5 mt-2"
        />
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab>Пользователи</v-tab>
            <v-tab>Индивидуальные уроки</v-tab>
            <v-tab>Групповые уроки</v-tab>
            <v-tab>Курсы</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-text>
                <UsersStatistics :dates="dates" />
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-text>
                <LessonStatistics
                  eventType="individual-lesson"
                  :dates="dates"
                />
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-text>
                <LessonStatistics eventType="group-lesson" :dates="dates" />
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-text>
                <LessonStatistics eventType="course-lesson" :dates="dates" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Users from "@/components/Statistic/Users";
// import Lessons from "@/components/Statistic/Lessons";

import moment from "moment";
import DateSelector from "@/components/DateSelector.vue";
import UsersStatistics from "@/components/Statistic/UsersStatistics.vue";
import LessonStatistics from "@/components/Statistic/LessonStatistics.vue";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  name: "Dashboard",
  components: {
    UsersStatistics,
    DateSelector,
    LessonStatistics,
    /*Users  Lessons */
  },
  data: () => ({
    tab: null,
    dates: null,
  }),
  created() {
    this.dates = [moment().format(DATE_FORMAT)];
  },
  methods: {
    setNewDates(newDates) {
      this.dates = newDates;
    },
  },
};
</script>
