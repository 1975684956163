<template>
  <div v-if="dataLoaded">
    <CcvDonutChart
      :data="circleDiagramData"
      :options="circleDiagramOptions"
      class="mb-8"
    />
    <div class="title">{{ chartTitle }}</div>
    <CcvGroupedBarChart :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import api from "../../../api";

export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.loadStatistics();
  },
  data() {
    return {
      dataLoaded: false,
      statistics: null,
      circleDiagramOptions: {
        legend: {
          alignment: "center",
        },
        color: {
          scale: {
            "Отмененные уроки": "#323031",
            "Не удавшиеся уроки": "#ff5a72",
            "Ученик не пришел на урок": "#9f80ff",
            "Репетитор не пришел на урок": "#ffcc00",
            "Урок прошел": "#39e65e",
          },
        },
        toolbar: {
          enabled: true,
          controls: [],
        },
        donut: {
          center: {
            label: "Уроков",
          },
          alignment: "center",
        },
      },
      chartOptions: {
        color: {
          scale: {
            "Отмененные уроки": "#323031",
            "Не удавшиеся уроки": "#ff5a72",
            "Ученик не пришел на урок": "#9f80ff",
            "Репетитор не пришел на урок": "#ffcc00",
            "Урок прошел": "#39e65e",
          },
        },
        axes: {
          left: {
            mapsTo: "value",
          },
          bottom: {
            scaleType: "labels",
            mapsTo: "key",
          },
        },
        height: "100%",
        toolbar: {
          enabled: true,
          controls: [],
          numberOfIcons: 0,
        },
        legend: {
          alignment: "center",
        },
      },
    };
  },
  methods: {
    async loadStatistics() {
      if (!this.dates?.length) throw new Error("Dates array should be passed");
      const params =
        this.dates.length === 1
          ? {
              date_start: this.dates[0],
              date_end: this.dates[0],
            }
          : {
              date_start: this.dates[0],
              date_end: this.dates[1],
            };
      try {
        switch (this.eventType) {
          case "individual-lesson": {
            const { data } = await api.lessons.individualLessonStatistic(
              params
            );
            this.statistics = data;
            break;
          }
          case "group-lesson": {
            const { data } = await api.lessons.groupLessonStatistic(params);
            this.statistics = data;
            break;
          }
          case "course-lesson": {
            const { data } = await api.lessons.courseLessonStatistic(params);
            this.statistics = data;
            break;
          }
        }

        this.dataLoaded = true;
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    chartData() {
      const dataSet = [];

      const {
        canceled: canceledLessonsStatistics,
        failed: failedLessonsStatistics,
        failedByStudent: failedByStudentLessonsStatistics,
        failedByTutor: failedByTutorLessonsStatistics,
        completedSuccess: completedLessonsStatistics,
      } = this.statistics?.chart_data;

      const transformedCanceledLessonsStatistics = Object.entries(
        canceledLessonsStatistics
      ).map((item) => {
        const [date, count] = item;
        return {
          group: "Отмененные уроки",
          key: date,
          value: count,
        };
      });

      const transformedFailedLessonsStatistics = Object.entries(
        failedLessonsStatistics
      ).map((item) => {
        const [date, count] = item;
        return {
          group: "Не удавшиеся уроки",
          key: date,
          value: count,
        };
      });

      const transformedFailedByStudentLessonsStatistics = Object.entries(
        failedByStudentLessonsStatistics
      ).map((item) => {
        const [date, count] = item;
        return {
          group: "Ученик не пришел на урок",
          key: date,
          value: count,
        };
      });

      const transformedFailedByTutorLessonsStatistics = Object.entries(
        failedByTutorLessonsStatistics
      ).map((item) => {
        const [date, count] = item;
        return {
          group: "Репетитор не пришел на урок",
          key: date,
          value: count,
        };
      });

      const transformedCompletedLessonsStatistics = Object.entries(
        completedLessonsStatistics
      ).map((item) => {
        const [date, count] = item;
        return {
          group: "Урок прошел",
          key: date,
          value: count,
        };
      });

      dataSet.push(
        ...transformedCanceledLessonsStatistics,
        ...transformedFailedLessonsStatistics,
        ...transformedFailedByStudentLessonsStatistics,
        ...transformedFailedByTutorLessonsStatistics,
        ...transformedCompletedLessonsStatistics
      );

      return dataSet;
    },
    circleDiagramData() {
      const failedLessonsCount = this.statistics?.general_statistic?.find(
        (item) => item?.status === "failed"
      )?.count;
      const failedByStudentLessonsCount =
        this.statistics?.general_statistic?.find(
          (item) => item?.status === "failedByStudent"
        )?.count;
      const failedByTutorLessonsCount =
        this.statistics?.general_statistic?.find(
          (item) => item?.status === "failedByTutor"
        )?.count;
      const cancelledLessonsCount = this.statistics?.general_statistic?.find(
        (item) => item?.status === "canceled"
      )?.count;
      const completedLessonsCount = this.statistics?.general_statistic?.find(
        (item) => item?.status === "completedSuccess"
      )?.count;
      return [
        {
          group: "Не удавшиеся уроки",
          value: failedLessonsCount || 0,
        },
        {
          group: "Ученик не пришел на урок",
          value: failedByStudentLessonsCount || 0,
        },
        {
          group: "Репетитор не пришел на урок",
          value: failedByTutorLessonsCount || 0,
        },
        {
          group: "Отмененные уроки",
          value: cancelledLessonsCount || 0,
        },
        {
          group: "Урок прошел",
          value: completedLessonsCount || 0,
        },
      ];
    },
    chartTitle() {
      switch (this.eventType) {
        case "individual-lesson": {
          return "Статистика проведения индивидуальных уроков";
        }
        case "group-lesson": {
          return "Статистика проведения групповых уроков";
        }
        case "course-lesson": {
          return "Статистика проведения курсов";
        }
        default:
          return null;
      }
    },
  },
  watch: {
    async dates() {
      await this.loadStatistics();
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  color: #161616;
  font-size: 16px;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}
</style>
