<template>
  <div v-if="dataLoaded" class="d-flex-col pa-4">
    <CcvDonutChart :data="circleDiagramData" :options="circleDiagramOptions" />
    <CcvAreaChart :data="chartData" :options="chartOptions" class="mt-8" />
  </div>
</template>

<script>
import Vue from "vue";
import "@carbon/charts/styles.css";
import chartsVue from "@carbon/charts-vue";
import api from "../../../api";

// IBM Plex should either be imported in your project by using Carbon
// or consumed manually through an import
// import "@/assets/plex-and-carbon-components.css";

Vue.use(chartsVue);

export default {
  name: "Chart",
  components: {},
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataLoaded: false,
      statistic: null,
      circleDiagramOptions: {
        toolbar: {
          enabled: true,
          controls: [
            { type: "Reset zoom" },
            { type: "Zoom in" },
            { type: "Zoom out" },
          ],
          numberOfIcons: 4,
        },
        color: {
          scale: {
            Ученики: "#419eff",
            Репетиторы: "#ff5a72",
          },
        },
        responsive: true,
        resizable: true,
        maintainAspectRatio: true,
        pie: {
          alignment: "center",
        },
        legend: {
          alignment: "center",
          display: false,
        },
        donut: {
          center: {
            label: "Пользователей",
          },
          alignment: "center",
        },
        height: "100%",
      },
      chartOptions: {
        title: "Регистрации пользователей",
        axes: {
          bottom: {
            mapsTo: "key",
            scaleType: "labels",
          },
          left: {
            mapsTo: "value",
            scaleType: "linear",
          },
        },
        color: {
          scale: {
            Ученики: "#419eff",
            Репетиторы: "#ff5a72",
          },
        },
        toolbar: {
          enabled: true,
          controls: [
            { type: "Reset zoom" },
            { type: "Zoom in" },
            { type: "Zoom out" },
          ],
          numberOfIcons: 4,
        },
        height: "100%",
      },
    };
  },
  async mounted() {
    await this.loadStatistic();
  },
  methods: {
    async loadStatistic() {
      if (!this.dates?.length) throw new Error("Dates array should be passed");
      const params =
        this.dates.length === 1
          ? {
              date_start: this.dates[0],
              date_end: this.dates[0],
            }
          : {
              date_start: this.dates[0],
              date_end: this.dates[1],
            };
      try {
        const { data } = await api.dashboard.statistic(params);
        this.statistic = data;
        this.dataLoaded = true;
      } catch (e) {
        console.error(e);
        this.statistic = null;
      }
    },
  },
  computed: {
    circleDiagramData() {
      const tutorsCount = this.statistic?.general_statistic?.find(
        (item) => item?.role_name === "tutor"
      )?.users_count;
      const studentsCount = this.statistic?.general_statistic?.find(
        (item) => item?.role_name === "student"
      )?.users_count;
      return [
        {
          group: "Ученики",
          value: studentsCount || 0,
        },
        {
          group: "Репетиторы",
          value: tutorsCount || 0,
        },
      ];
    },

    chartData() {
      const dataSet = [];

      const { students: studentsStatistic, tutors: tutorsStatistic } =
        this.statistic?.chart_data;

      const transformedStudentStatistics = Object.entries(
        studentsStatistic
      ).map((item) => {
        const [date, registrationsCount] = item;
        return {
          group: "Ученики",
          key: date,
          value: registrationsCount,
        };
      });

      const transformedTutorStatistics = Object.entries(tutorsStatistic).map(
        (item) => {
          const [date, registrationsCount] = item;
          return {
            group: "Репетиторы",
            key: date,
            value: registrationsCount,
          };
        }
      );

      dataSet.push(
        ...transformedTutorStatistics,
        ...transformedStudentStatistics
      );

      return dataSet;
    },
  },
  watch: {
    async dates() {
      await this.loadStatistic();
    },
  },
};
</script>
